export const appHead = {"link":[{"rel":"alternate","hreflang":"de-AT","href":"https://partner.allekommen.at"},{"rel":"alternate","hreflang":"de-DE","href":"https://partner.allekommen.com"},{"rel":"alternate","hreflang":"de","href":"https://partner.allekommen.com"},{"rel":"alternate","hreflang":"x-default","href":"https://partner.allekommen.com"},{"rel":"shortcut icon","type":"image/x-icon","href":"https://cdn.allekommen.at/allekommen/favicons/favicon.ico"},{"rel":"icon","type":"image/svg+xml","href":"https://cdn.allekommen.at/allekommen/favicons/favicon.svg"},{"rel":"apple-touch-icon","type":"image/png","href":"https://cdn.allekommen.at/allekommen/favicons/favicon.png"}],"meta":[{"charset":"utf-8"},{"name":"robots","content":"index, follow"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"format-detection","content":"telephone=no"},{"name":"google-site-verification","content":"MIp1GluemvY2yfBCF-eQ8o8LDanjFYdXUoR9N5x4gPw"},{"property":"og:type","content":"website"}],"style":[],"script":[{"src":"//js-eu1.hs-scripts.com/26998599.js","defer":true,"async":true},{"src":"https://api.goaffpro.com/loader.js?shop=qjs7qxe4gh","defer":true,"async":true}],"noscript":[],"title":"allekommen.at Partner","htmlAttrs":{"lang":"de","data-theme":"light"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false